import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import BusinessIcon from '@mui/icons-material/Business';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import GroupIcon from '@mui/icons-material/Group';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ReportIcon from '@mui/icons-material/Report';
import SettingsIcon from '@mui/icons-material/Settings';

import NavBar, { NavGroups } from 'flyid-ui-components/dist/layout/NavBar';
import useOnceEffect from 'src/hooks/useOnceEffect';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useIntl } from 'react-intl';
import { useMemo } from 'react';
import { auth } from 'src/firebase/firebase';
import { SUPPORTED_LOCALES, readableLocaleByLocale } from '../../util/locale';
import { isAdminProf } from '../../util/helpers/user';

import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import { MyDialogState, updateUi } from 'src/redux/reducers/uiReducer';
import { Actions } from 'src/redux/actions/actionTypes';
import { setLanguage as setLanguageAction } from '../../redux/reducers/localeReducer';
import { fetchProfileImages } from 'src/redux/actions/userActions';

import noImg from '../../assets/images/avatar-no-img.png';
import logo from '../../assets/images/logo/logo_fly_id.png';
import squareLogo from '../../assets/images/logo/pid_logo_primary_m.png';

const NavBarImpl: React.FC = (props) => {
  const [authUser, authLoading] = useAuthState(auth);
  const signedIn = !authLoading && Boolean(authUser);
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { $t } = intl;

  const { children } = props;
  const { userData, locale } = useAppSelector(({ user, locale }) => ({
    userData: user,
    locale
  }));

  const { uid: selfUid, profile, isLoaded: profileDoneLoading, profileError } = userData;
  const isProfileLoaded = profileDoneLoading && !profileError;
  const profileImageData = selfUid ? userData.profilePics[selfUid] : undefined;

  const routes = useMemo(() => {
    const _routes: NavGroups = [];

    if (signedIn) {
      _routes.push({
        key: 'user',
        name: $t({ id: 'admin.userActions' }),
        icon: <GroupIcon />,
        children: [
          ...(isAdminProf(profile)
            ? [
                {
                  name: $t({ id: 'nav.manageusers' }),
                  path: '/manageUsers',
                  icon: <ManageAccountsIcon />
                }
              ]
            : [])
        ]
      }),
        _routes.push({
          key: 'company',
          name: $t({ id: 'admin.companyActions' }),
          icon: <BusinessIcon />,
          children: [
            ...(isAdminProf(profile)
              ? [
                  {
                    name: $t({ id: 'admin.addCompany' }),
                    path: '/addCompany',
                    icon: <AddBusinessIcon />
                  }
                ]
              : [])
          ]
        }),
        _routes.push({
          key: 'license',
          name: $t({ id: 'admin.licenseActions' }),
          icon: <FactCheckIcon />,
          children: [
            ...(isAdminProf(profile)
              ? [
                  {
                    name: $t({ id: 'nav.managelicenses' }),
                    path: '/manageLicenses',
                    icon: <SettingsIcon />
                  },
                  {
                    name: $t({ id: 'admin.criticalLicenses' }),
                    path: '/criticalLicenses',
                    icon: <ReportIcon />
                  },
                  {
                    name: $t({ id: 'admin.replaceFlyidLicense' }),
                    path: '/replaceFlyidLicense',
                    icon: <ChangeCircleIcon />
                  }
                ]
              : [])
          ]
        });
    }
    return _routes;
  }, [signedIn, isProfileLoaded, profile, locale]);

  const showLogoutDialogConfirmation = () => {
    dispatch(
      updateUi({
        dialog: new MyDialogState({
          title: $t({ id: 'nav.logoutTitle' }),
          message: $t({ id: 'nav.logoutMsg' }),
          show: true
        }).setConfirmAction(Actions.LOGOUT)
      })
    );
  };

  // Fetch own profile picture
  useOnceEffect(() => {
    // Only try to download profile images if properly authenticated
    if (signedIn && isProfileLoaded) {
      if (!profileImageData?.isLoading && !profileImageData?.isLoaded) {
        dispatch(
          fetchProfileImages({
            profileUids: [selfUid],
            company: profile.company,
            isThumb: false
          })
        );
        return [true];
      }
    }
    return [false];
  });

  return (
    <NavBar
      userData={userData}
      signedIn={signedIn}
      routes={routes}
      logo={logo}
      noImg={noImg}
      squareLogo={squareLogo}
      logoutAction={showLogoutDialogConfirmation}
      localesData={{
        onLocaleChange: (_locale) => dispatch(setLanguageAction(_locale)),
        supportedLocales: SUPPORTED_LOCALES,
        readableLocales: readableLocaleByLocale
      }}
    >
      {children}
    </NavBar>
  );
};

export default NavBarImpl;
