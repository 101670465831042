import { AnyAction, combineReducers } from 'redux';
import localeReducer from './localeReducer';
import pictureReducer from './pictureReducer';
import uiReducer from './uiReducer';
import userReducer from './userReducer';
import firestoreReducer from './firestoreReducer';

// COR turn off on release
const forceLoggerOnProduction = false;
const env = process.env.REACT_APP_ENV;
const logger = (_state, action: AnyAction) => {
  if (env?.includes('staging') || env?.includes('local') || forceLoggerOnProduction) {
    console.log(action.type, action.payload);
  }
  return null;
};

const combinedReducers = combineReducers({
  logger,
  user: userReducer,
  ui: uiReducer,
  locale: localeReducer,
  picture: pictureReducer,
  firestore: firestoreReducer
});

export default combinedReducers;
